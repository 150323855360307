import React from "react";
import { Helmet } from "react-helmet";

const Layout = ({ children, title, description, favicon }) => (
  <div>
    <Helmet>
      <title>{title || "Prima Gifting"}</title>
      <meta
        name="description"
        content={
          description ||
          "PrimaGifting is the best gifting company we have more than 2000+ products we are here to solve all your gifting needs"
        }
      />
      <link rel="icon" href={favicon || "/assets/navLogo.png"} />
      <meta property="og:title" content={title || "Prima Gifting"} />
      <meta
        property="og:description"
        content={
          description ||
          "PrimaGifting is the best gifting company we have more than 2000+ products we are here to solve all your gifting needs"
        }
      />
    </Helmet>
    {children}
  </div>
);

export default Layout;
