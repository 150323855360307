import React from "react";
import "./ProductCategoriesCard.css";
import { useNavigate } from "react-router-dom";

const ProductCategoriesCard = ({ category }) => {
  const navigate = useNavigate();

  const handleClick = (name) => {
    navigate("/b2b-product-list", { state: { categoryName: name } });
  };

  return (
    <div
      className="product-cate-card"
      onClick={() => handleClick(category.name)}
    >
      <div className="product-cate-img-container">
        <img
          src={`data:image/png;base64,${category.image}`}
          className="product-cate-img"
          alt="Sample"
        />
      </div>
      <div className="product-cate-title">{category.name}</div>
    </div>
  );
};

export default ProductCategoriesCard;
