import React from "react";
import { Carousel } from "react-responsive-carousel";
import Button from "../Button/Button";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Baner.css";

const Baner = ({ bannerData = [] }) => {
  return (
    <div className="baner-container">
      <Carousel
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        interval={2000}
        transitionTime={500}
      >
        {bannerData.map((banner, index) => (
          <div key={index} className="baner-slide">
            <img
              src={`data:image/jpeg;base64,${banner.imagePath}`}
              alt={banner.title}
              className="baner-image"
            />
            <div className="baner-content">
              <p className="baner-title">{banner.title}</p>
              <p className="baner-subtitle">{banner.subtitle}</p>
              <Button label={`Custom Solutions`} />
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Baner;
