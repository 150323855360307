import React, { useState } from "react";
import "./B2BQuoteListPage.css";
import { useQuote } from "../../../contexts/QuoteContext";
import axios from "axios";
import Button from "../../../Components/Button/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";

const B2BQuoteListPage = () => {
  const { quote, removeQuote } = useQuote();
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    location: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    const baseUrl = process.env.REACT_APP_BASE_URL;
    const updatedFormData = {
      ...formData,
      quote,
    };

    try {
      const response = await axios.post(
        `${baseUrl}/api/quote/saveQuote`,
        updatedFormData
      );
      if (response.data.success) {
        quote.forEach((item) => removeQuote(item));
        setFormData({
          name: "",
          companyName: "",
          location: "",
          email: "",
          message: "",
        });
        toast.success("Quote submitted successfully!");
      }
    } catch (error) {
      toast.error("Error saving quote. Please try again.");
      console.error("Error saving quote:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="quote-container">
        <ToastContainer />
        <table>
          <tr>
            <th>SKU</th>
            <th>Product</th>
            <th style={{ width: "40vw" }}>Product Description</th>
            <th>MOQ</th>
          </tr>
          {quote.map((item) => {
            const { featuredImage } = item.images;
            return (
              <tr key={item.id}>
                <td>
                  <div className="table-data">
                    <i
                      className="fa-regular fa-circle-xmark"
                      onClick={() => removeQuote(item)}
                    ></i>
                    <p>{item.sku}</p>
                    <img
                      src={`${featuredImage}`}
                      alt={quote.title}
                      className="baner-image"
                    />
                  </div>
                </td>
                <td>
                  <p>{item.name}</p>
                </td>
                <td>
                  <p>{item.shortDis}</p>
                </td>
                <td>
                  <p>{item.quantity}</p>
                </td>
              </tr>
            );
          })}
        </table>
        <p className="quote-header">
          To get the quote please fill your information
        </p>
        <div className="quote-form-container">
          <table>
            <tr>
              <td className="quote-label-border">
                <label>Name:</label>
              </td>
              <td>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="quote-label-border">
                <label>Company Name:</label>
              </td>
              <td>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="quote-label-border">
                <label>Location:</label>
              </td>
              <td>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="quote-label-border">
                <label>E-mail:</label>
              </td>
              <td>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <td className="quote-label-border">
                <label>Message:</label>
              </td>
              <td>
                <textarea
                  type="text"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </td>
            </tr>
          </table>
          <button className="quote-btn" onClick={handleSubmit}>
            {loading ? (
              <ClipLoader size={18} color={"#fff"} loading={loading} />
            ) : (
              <Button color="#EBA9D7" label="Send" />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default B2BQuoteListPage;
