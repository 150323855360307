import React, { useEffect, useState } from "react";
import "./AdminDashboard.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminDashboard = () => {
  const [data, setData] = useState({
    totalRevenue: 0,
    dailySales: 0,
    productCount: 0,
    orders: [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/order/summary`);
        setData(response.data);
        setFilteredOrders(response.data.orders);
      } catch (error) {
        console.error("Error fetching orders data:", error);
      }
    };

    fetchData();
  }, [baseUrl]);

  useEffect(() => {
    const filterOrders = () => {
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = data.orders.filter((order) => {
        const customerName = order.name.toLowerCase();
        const productName = order.cart[0]?.name.toLowerCase() || "";
        const orderStatus = order.status.toLowerCase();
        return (
          customerName.includes(lowercasedQuery) ||
          productName.includes(lowercasedQuery) ||
          orderStatus.includes(lowercasedQuery)
        );
      });
      setFilteredOrders(filtered);
    };

    filterOrders();
  }, [searchQuery, data.orders]);

  const handleRowClick = (orderId) => {
    navigate(`/order-details/${orderId}`);
  };

  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard-search-container">
        <input
          type="text"
          placeholder="Search orders..."
          className="admin-search-input"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button className="admin-search-button">
          <i className="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>

      <div className="admin-dashboard-card-container">
        <div className="admin-dashboard-card">
          <i className="fa-solid fa-arrow-trend-up"></i>
          <p>Total Revenue</p>
          <p>₹{data.totalRevenue.toLocaleString()}</p>
        </div>
        <div className="admin-dashboard-card">
          <i className="fa-solid fa-cart-shopping"></i>
          <p>Daily Sales</p>
          <p>₹{data.dailySales.toLocaleString()}</p>
        </div>
        <div className="admin-dashboard-card">
          <i className="fa-solid fa-box"></i>
          <p>Total Products Sold</p>
          <p>{data.productCount}</p>
        </div>
      </div>

      <div className="admin-dashboard-table-container">
        <p>Last Orders</p>
        <table>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Date</th>
              <th>Price</th>
              <th>Product</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.map((order) => {
              const firstItem = order.cart[0];
              return (
                <tr
                  key={order._id}
                  onClick={() => handleRowClick(order._id)}
                  className="clickable-row"
                >
                  <td>{order.name}</td>
                  <td>{new Date(order.orderedAt).toLocaleDateString()}</td>
                  <td>
                    ₹{(firstItem.price * firstItem.quantity).toLocaleString()}
                  </td>
                  <td>{firstItem.name}</td>
                  <td
                    className={
                      order.status === "delivered"
                        ? "status-delivered"
                        : "status-pending"
                    }
                  >
                    {order.status}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminDashboard;
