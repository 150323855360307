import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [categories, setCategories] = useState([]);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          method: "get",
          url: `${baseUrl}/api/category`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios(config);
        setCategories(response.data.slice(0, 3));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    try {
      const response = await fetch(`${baseUrl}/api/letter/newsletter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setEmail("");
      } else {
        console.log("Failed to save email");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCategoryClick = (categoryId) => {
    navigate(`/b2b-product-list`);
  };
  return (
    <div className="footer-container">
      <div className="footer-logo-container">
        <div className="footer-logo">
          <img
            src={"/assets/navLogo.png"}
            className="cat-card-img"
            alt="Logo"
          />{" "}
        </div>
        <div className="footer-dis">
          PrimaGifting is the best gifting company we have more than 2000+
          products we are here to solve all your gifting needs
        </div>
      </div>
      <hr className="line" />
      <div className="footer-links-container">
        <p className="footer-header">Usefull Links</p>

        {categories.map((cat) => (
          <div
            className="cate-card"
            key={cat._id}
            onClick={() => handleCategoryClick()} // Add onClick handler
          >
            <p className="footer-link">{cat.name}</p>
          </div>
        ))}
        <div
          className="cate-card"
          onClick={() => handleCategoryClick()} // Add onClick handler
        >
          <p className="footer-link">More</p>
        </div>
      </div>
      <hr className="line" />
      <div className="footer-links-container">
        <p className="footer-header">Newsletter</p>
        <p className="footer-link">
          Subscribe to our newsletter to receive the latest promotions and news!
        </p>
        <form onSubmit={handleEmailSubmit}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            className="footer-text"
          />
          <button type="submit" className="footer-button">
            Submit
          </button>
        </form>
      </div>
      <div className="footer-links-container">
        <p className="footer-header">Social Links</p>
        <p className="footer-link">
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram className="footer-icon" />
          </a>
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook className="footer-icon" />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaYoutube className="footer-icon" />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
