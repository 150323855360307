import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Graphics.css";
import { FaImages, FaTrash, FaPlus, FaEdit } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";

const BannerUploadForm = () => {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [banners, setBanners] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingBannerId, setEditingBannerId] = useState(null);
  const [loading, setLoading] = useState(false); // Loading state
  const [fetching, setFetching] = useState(false); // Fetching state

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    setFetching(true); // Start loader when fetching
    try {
      const response = await axios.get(`${baseUrl}/api/banner/getbanner`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBanners(response.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
      setFetching(false); // Stop loader after fetching
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader when submitting

    const formData = new FormData();
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    if (image) {
      formData.append("image", image);
    }

    try {
      if (isEditing) {
        // Update existing banner
        await axios.put(
          `${baseUrl}/api/banner/updatebanner/${editingBannerId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        // Add new banner
        await axios.post(`${baseUrl}/api/banner/addbanner`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
      }

      fetchBanners(); // Refresh banners after adding or editing
      setIsModalOpen(false); // Close modal after submission
      resetForm(); // Reset the form fields
    } catch (error) {
      console.error("Error uploading banner:", error);
    } finally {
      setLoading(false); // Stop loader after submission
    }
  };

  const handleDelete = async (id) => {
    setLoading(true); // Start loader when deleting
    try {
      await axios.delete(`${baseUrl}/api/banner/deletebanner/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchBanners(); // Refresh banners after deletion
    } catch (error) {
      console.error("Error deleting banner:", error);
    } finally {
      setLoading(false); // Stop loader after deletion
    }
  };

  const handleEdit = (banner) => {
    setIsEditing(true);
    setEditingBannerId(banner._id);
    setTitle(banner.title);
    setSubtitle(banner.subtitle);
    setImagePreview(`data:image/jpeg;base64,${banner.imagePath}`);
    setIsModalOpen(true);
  };

  const resetForm = () => {
    setTitle("");
    setSubtitle("");
    setImage(null);
    setImagePreview("");
    setIsEditing(false);
    setEditingBannerId(null);
  };

  const truncTitle = (val) => {
    if (val.length > 20) {
      return val.slice(0, 20) + "...";
    } else {
      return val;
    }
  };

  return (
    <div className="upload-post-container">
      <h1 className="upload-post-heading">Banners</h1>
      <button
        onClick={() => setIsModalOpen(true)}
        className="add-graphics-button"
      >
        <FaPlus /> Add Graphics
      </button>

      {/* Loader while fetching */}
      {fetching ? (
        <div className="loader-container">
          <ClipLoader color="#00704a" loading={fetching} size={50} />
        </div>
      ) : (
        <div className="banner-table-container">
          <table className="banner-table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Title</th>
                <th>Subtitle</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {banners.map((banner) => (
                <tr key={banner._id}>
                  <td>
                    <img
                      src={`data:image/jpeg;base64,${banner.imagePath}`}
                      alt={banner.title}
                      className="banner-table-image"
                    />
                  </td>
                  <td>{truncTitle(banner.title)}</td>
                  <td>{banner.subtitle}</td>
                  <td>
                    <button
                      onClick={() => handleEdit(banner)}
                      className="edit-button"
                      disabled={loading} // Disable button while loading
                    >
                      <FaEdit /> Edit
                    </button>
                    <button
                      onClick={() => handleDelete(banner._id)}
                      className="delete-button"
                      disabled={loading} // Disable button while loading
                    >
                      <FaTrash /> Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{isEditing ? "Edit Banner" : "Add New Banner"}</h2>
            <form onSubmit={handleSubmit} className="upload-post-form">
              <input
                type="text"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="upload-post-input"
                required
              />
              <input
                type="text"
                placeholder="Subtitle"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
                className="upload-post-input"
                required
              />
              <label className="upload-post-file-label">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="upload-post-file-input"
                  required={!isEditing} // Image input is not required when editing
                />
                <FaImages size={24} className="upload-post-file-icon" />
                <span className="upload-post-file-text">Choose an image</span>
              </label>
              {imagePreview && (
                <div className="image-preview-container">
                  <img
                    src={imagePreview}
                    alt="Image Preview"
                    className="image-preview"
                  />
                </div>
              )}
              <div className="upload-post-button-container">
                <button
                  type="submit"
                  className="upload-post-button"
                  disabled={loading} // Disable button while loading
                >
                  {loading ? (
                    <ClipLoader color="#ffffff" size={20} />
                  ) : isEditing ? (
                    "Update"
                  ) : (
                    "Upload"
                  )}
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setIsModalOpen(false);
                    resetForm();
                  }}
                  className="modal-close-button"
                  disabled={loading} // Disable button while loading
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BannerUploadForm;
