import React, { useEffect, useState } from "react";
import Baner from "../../../Components/Baner/Baner";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import Testimonials from "../../../Components/Testimonials/Testimonials";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import LatestArrivalsB2C from "../../../Components/LatestArrivalsB2C/LatestArrivalsB2C";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const B2CHomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  if (token) {
    const decodedToken = jwtDecode(token);
    console.log("Decoded Token:", decodedToken);
  }

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredProducts = response.data.filter((item) =>
          item.productType.includes("B2C")
        );
        setProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [baseUrl, token]);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        console.log(response.data);
        setBannerData(response.data);
        console.log("Banner Data:", response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBannerData();
  }, []);

  return (
    <>
      <Baner bannerData={bannerData} />
      <LatestArrivalsB2C color="blue" products={products} />
      <ProductCategories categories={categories} />
      {/* <MostPopularB2C color="blue"/> */}
      <Testimonials color="blue" />
      <GetInTouch color="blue" />
    </>
  );
};

export default B2CHomePage;

// import React, { useEffect, useState } from "react";
// import Baner from "../../../Components/Baner/Baner";
// import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
// import Testimonials from "../../../Components/Testimonials/Testimonials";
// import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
// import LatestArrivalsB2C from "../../../Components/LatestArrivalsB2C/LatestArrivalsB2C";
// import MostPopularB2C from "../../../Components/MostPopularB2C/MostPopularB2C";
// import axios from "axios";
// import { jwtDecode } from "jwt-decode";
// import Nav from "../../../Components/Nav/Nav";

// const B2CHomePage = () => {
//   const [products, setProducts] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const token = localStorage.getItem("token");
//   const baseUrl = process.env.REACT_APP_BASE_URL;

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     console.log("Decoded Token:", decodedToken);
//   }

//   useEffect(() => {
//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get(`${baseUrl}/api/product`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setProducts(response.data);
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       }
//     };

//     const fetchCategories = async () => {
//       try {
//         const response = await axios.get(`${baseUrl}/api/category`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });
//         setCategories(response.data);
//       } catch (error) {
//         console.error("Error fetching products:", error);
//       }
//     };

//     fetchProducts();
//     fetchCategories();
//   }, [baseUrl, token]);

//   return (
//     <>
//       {/* <Nav color={"blue"} /> */}
//       <Baner label="Welcome to prima gifting" />
//       <LatestArrivalsB2C color="blue" products={products} />
//       <ProductCategories categories={categories} />
//       {/* <MostPopularB2C color="blue"/> */}
//       <Testimonials color="blue" />
//       <GetInTouch color="blue" />
//     </>
//   );
// };

// export default B2CHomePage;
