import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./B2BProductListBrand.css";
import axios from "axios";
import ProductCatalogueCard from "../../../Components/ProductCatalogueCard/ProductCatalogueCard";
import Nav from "../../../Components/Nav/Nav";

const B2BProductListBrand = () => {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sortOrder, setSortOrder] = useState("latest");
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const { id } = useParams();
  const [selectedBrandId, setSelectedBrandId] = useState(id);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_BASE_URL;

    const fetchProducts = async () => {
      const config = {
        method: "get",
        url: `${baseUrl}/api/product/brand?brand=${selectedBrandId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios(config);
        setProducts(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/brand`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBrands(response.data);
        if (!id && response.data.length > 0) {
          setSelectedBrandId(response.data[0]._id);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchBrands();
    fetchProducts();
  }, [selectedBrandId]);

  const selectBrand = async (id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      url: `${baseUrl}/api/product/brand?brand=${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setProducts(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  const sortedProducts = products.sort((a, b) => {
    if (sortOrder !== "latest") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    } else {
      return new Date(a.createdAt) - new Date(b.createdAt);
    }
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="product-list-container">
      <div className="product-list-title">Product catalogue</div>
      <div className="product-list-showcase">
        <div className="product-list-cat-list-container">
          <h1>Brands</h1>
          {brands.map((brand) => (
            <p key={brand._id} onClick={() => selectBrand(brand._id)}>
              {brand.name}
            </p>
          ))}
        </div>
        <div className="product-list-cat-container">
          <div className="product-list-selection">
            <select onChange={handleSortOrderChange} value={sortOrder}>
              <option value="latest">Latest</option>
              <option value="oldest">Oldest</option>
            </select>
            <p>
              Showing {indexOfFirstProduct + 1} to{" "}
              {Math.min(indexOfLastProduct, products.length)} of{" "}
              {products.length} results
            </p>
          </div>
          <div className="product-list-card-container">
            {currentProducts.map((product) => (
              <ProductCatalogueCard key={product._id} product={product} />
            ))}
          </div>
          <div className="pagination">
            {Array.from(
              { length: Math.ceil(products.length / productsPerPage) },
              (_, index) => (
                <button key={index + 1} onClick={() => paginate(index + 1)}>
                  {index + 1}
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2BProductListBrand;
