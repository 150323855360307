import React, { useEffect, useState } from "react";
import Baner from "../../../Components/Baner/Baner";
import LatestArrivals from "../../../Components/LatestArrivals/LatestArrivals";
import ProductCategories from "../../../Components/ProductCategories/ProductCategories";
import Testimonials from "../../../Components/Testimonials/Testimonials";
import GetInTouch from "../../../Components/GetInTouch/GetInTouch";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../../../Components/Layout/Layout";

const B2BHomePage = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bannerData, setBannerData] = useState([]);

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/product`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const filteredProducts = response.data.filter(
          (item) => item.productType.includes("B2B") && item.isLatest
        );
        console.log(filteredProducts);
        setProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/category`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [baseUrl, token]);

  useEffect(() => {
    const fetchBannerData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/banner/getbanner`);
        console.log(response.data);
        setBannerData(response.data);
        console.log("Banner Data:", response.data);
      } catch (error) {
        console.error("Error fetching banners:", error);
      }
    };

    fetchBannerData();
  }, []);
  return (
    <>
      {/* <Nav /> */}
      <Layout
        title="B2B | PrimaGifting"
        description="Welcome to the home page."
        favicon="/assets/navLogo.png"
      >
        <Baner bannerData={bannerData} />
        <LatestArrivals products={products} />
        <ProductCategories categories={categories} />
        <GetInTouch />
      </Layout>
    </>
  );
};

export default B2BHomePage;
