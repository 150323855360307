import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  matchPath,
} from "react-router-dom";
import SideBar from "./Components/SideBar/SideBar";
import AdminDashboard from "./Pages/AdminPages/AdminDashboard/AdminDashboard";
import AdminLogin from "./Pages/AdminPages/AdminLogin/AdminLogin";
import AdminProducts from "./Pages/AdminPages/AdminProducts/AdminProducts";
import AdminAddProduct from "./Pages/AdminPages/AdminAddProduct/AdminAddProduct";
import AdminEditProduct from "./Pages/AdminPages/AdminEditProduct/AdminEditProduct";
import AdminCategories from "./Pages/AdminPages/AdminCategories/AdminCategories";
import AdminBrands from "./Pages/AdminPages/AdminBrands/AdminBrands";
import AdminCompanies from "./Pages/AdminPages/AdminCompanies/AdminCompanies";
import AdminOrders from "./Pages/AdminPages/AdminOrders/AdminOrders";
import AdminAddCompanies from "./Pages/AdminPages/AdminAddCompanies/AdminAddCompanies";
import AdminEditCompanies from "./Pages/AdminPages/AdminEditCompanies/AdminEditCompanies";
import AdminAddCategories from "./Pages/AdminPages/AdminAddCategories/AdminAddCategories";
import AdminEditCategories from "./Pages/AdminPages/AdminEditCategories/AdminEditCategories";
import AdminEditBrands from "./Pages/AdminPages/AdminEditBrands/AdminEditBrands";
import AdminAddBrands from "./Pages/AdminPages/AdminAddBrands/AdminAddBrands";
import AdminLogout from "./Pages/AdminPages/AdminLogout/AdminLogout";
import PrivateRoute from "./Components/PrivateRoute/PrivateRoute";
import B2BHomePage from "./Pages/B2B/B2BHomePage/B2BHomePage";
import Nav from "./Components/Nav/Nav";
import Footer from "./Components/Footer/Footer";
import B2BProductPage from "./Pages/B2B/B2BProductPage/B2BProductPage";
import B2BQuoteListPage from "./Pages/B2B/B2BQuoteListPage/B2BQuoteListPage";
import B2BContactUsPage from "./Pages/B2B/B2BContactUsPage/B2BContactUsPage";
import B2CHomePage from "./Pages/B2C/B2CHomePage/B2CHomePage";
import B2CProductPage from "./Pages/B2C/B2CProductPage/B2CProductPage";
import B2CCartPage from "./Pages/B2C/B2CCartPage/B2CCartPage";
import B2CShipping from "./Pages/B2C/B2CShipping/B2CShipping";
import B2EHomePage from "./Pages/B2E/B2EHomePage/B2EHomePage";
import B2EProductPage from "./Pages/B2E/B2EProductPage/B2EProductPage";
import B2ECartPage from "./Pages/B2E/B2ECartPage/B2ECartPage";
import B2EShipping from "./Pages/B2E/B2EShipping/B2EShipping";
import Register from "./Pages/Register/Register";
import Login from "./Pages/Login/Login";
import B2BProductList from "./Pages/B2B/B2BProductList/B2BProductList";
import B2BProductListBrand from "./Pages/B2B/B2BProductListBrand/B2BProductListBrand";
import B2CProductListBrand from "./Pages/B2C/B2CProductListBrand/B2CProductListBrand";
import Graphics from "./Pages/AdminPages/Graphics/Graphics";
import AdminQuote from "./Pages/AdminPages/AdminQuote/AdminQuote";
import AdminConsultation from "./Pages/AdminPages/AdminConsultation/AdminConsultation";
import AdminNewsLetter from "./AdminPages/AdminNewsLetter/AdminNewsLetter";
import AdminOrderDetailsPage from "./Pages/AdminPages/AdminOrderDetails/AdminOrderDetails";
import AdminOrderDetails from "./Pages/AdminPages/AdminOrderDetails/AdminOrderDetails";

const checkPathMatch = (paths, pathname) => {
  return paths.some((path) => matchPath(path, pathname));
};

const App = () => {
  const location = useLocation();
  const showSidebar = checkPathMatch(
    [
      "/admin-dashboard",
      "/products",
      "/add-product",
      "/edit-product/:id",
      "/companies",
      "/add-companies",
      "/edit-companies/:id",
      "/categories",
      "/add-categories",
      "/edit-categories/:id",
      "/brands",
      "/add-brand",
      "/edit-brand/:id",
      "/orders",
      "/graphic",
      "/quote",
      "/consultation",
      "/newsletter",
      "/order-details/:id",
    ],
    location.pathname
  );
  const showNavB2B = checkPathMatch(
    [
      "/",
      "/b2b-home-page",
      "/b2b-product-list-brand",
      "/b2b-product-list/:id",
      "/b2b-product-list",
      "/b2b-product-page/:id",
      "/b2b-qoute-page",
      "/b2b-contact-us-page",
    ],
    location.pathname
  );
  const showNavB2C = checkPathMatch(
    [
      "/b2c-product-list-brand",
      "/b2c-home-page",
      "/b2c-product-page/:id",
      "/b2c-cart-page",
      "/b2c-shipping",
    ],
    location.pathname
  );
  const showNavB2E = checkPathMatch(
    [
      "/b2e-home-page",
      "/b2e-product-page/:id",
      "/b2e-cart-page",
      "/b2e-shipping",
    ],
    location.pathname
  );
  const hideDiv = checkPathMatch(["/register", "/login"], location.pathname);
  return (
    <>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      {showNavB2B && <Nav />}
      <Routes>
        {/* <Route path='/b2b-home-page' element={<B2BHomePage />} /> */}
        <Route path="/" element={<B2BHomePage />} />
        <Route path="/b2b-product-list/:id" element={<B2BProductList />} />
        <Route path="/b2b-product-page/:id" element={<B2BProductPage />} />
        <Route path="/b2b-product-list" element={<B2BProductList />} />
        <Route
          path="/b2b-product-list-brand"
          element={<B2BProductListBrand />}
        />
        <Route path="/b2b-qoute-page" element={<B2BQuoteListPage />} />
        <Route path="/b2b-contact-us-page" element={<B2BContactUsPage />} />
      </Routes>
      {showNavB2B && <Footer />}

      {showNavB2C && <Nav color="blue" />}
      <Routes>
        <Route path="/b2c-home-page" element={<B2CHomePage />} />
        {/* <Route path='/' element={<B2CHomePage />} /> */}
        <Route path="/b2c-product-page/:id" element={<B2CProductPage />} />
        <Route
          path="/b2c-product-list-brand"
          element={<B2CProductListBrand />}
        />
        <Route path="/b2c-cart-page" element={<B2CCartPage />} />
        <Route path="/b2c-shipping" element={<B2CShipping />} />
      </Routes>
      {showNavB2C && <Footer />}

      {showNavB2E && <Nav color="blue" />}
      <Routes>
        <Route path="/b2e-home-page" element={<B2EHomePage />} />
        <Route path="/b2e-product-page/:id" element={<B2EProductPage />} />
        <Route path="/b2e-cart-page" element={<B2ECartPage />} />
        <Route path="/b2e-shipping" element={<B2EShipping />} />
      </Routes>
      {showNavB2E && <Footer />}

      {!showNavB2B && !showNavB2C && !showNavB2E && !hideDiv && (
        <div className="admin-main-container">
          {showSidebar && <SideBar />}
          <Routes>
            <Route path="/admin-login" element={<AdminLogin />} />
            <Route path="/admin-logout" element={<AdminLogout />} />
            <Route
              path="/admin-dashboard"
              element={<PrivateRoute element={AdminDashboard} />}
            />
            <Route
              path="/products"
              element={<PrivateRoute element={AdminProducts} />}
            />
            <Route
              path="/consultation"
              element={<PrivateRoute element={AdminConsultation} />}
            />
            <Route
              path="/newsletter"
              element={<PrivateRoute element={AdminNewsLetter} />}
            />
            <Route
              path="/quote"
              element={<PrivateRoute element={AdminQuote} />}
            />
            <Route
              path="/add-product"
              element={<PrivateRoute element={AdminAddProduct} />}
            />
            <Route
              path="/edit-product/:id"
              element={<PrivateRoute element={AdminEditProduct} />}
            />
            <Route
              path="/companies"
              element={<PrivateRoute element={AdminCompanies} />}
            />
            <Route
              path="/graphic"
              element={<PrivateRoute element={Graphics} />}
            />
            <Route
              path="/add-companies"
              element={<PrivateRoute element={AdminAddCompanies} />}
            />
            <Route
              path="/edit-companies/:id"
              element={<PrivateRoute element={AdminEditCompanies} />}
            />
            <Route
              path="/categories"
              element={<PrivateRoute element={AdminCategories} />}
            />
            <Route
              path="/add-categories"
              element={<PrivateRoute element={AdminAddCategories} />}
            />
            <Route
              path="/edit-categories/:id"
              element={<PrivateRoute element={AdminEditCategories} />}
            />
            <Route
              path="/brands"
              element={<PrivateRoute element={AdminBrands} />}
            />
            <Route
              path="/add-brand"
              element={<PrivateRoute element={AdminAddBrands} />}
            />
            <Route
              path="/edit-brand/:id"
              element={<PrivateRoute element={AdminEditBrands} />}
            />
            <Route
              path="/orders"
              element={<PrivateRoute element={AdminOrders} />}
            />
            <Route
              path="/order-details/:id"
              element={<PrivateRoute element={AdminOrderDetails} />}
            />
          </Routes>
        </div>
      )}
    </>
  );
};

export default App;
