import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./AdminOrders.css";

const AdminOrders = () => {
  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSort, setSelectedSort] = useState("latest"); // Default sort
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/order`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setOrders(response.data);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };

    fetchOrders();
  }, [token, baseUrl]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortChange = (e) => {
    setSelectedSort(e.target.value);
  };

  const handleOrderClick = (id) => {
    navigate(`/order-details/${id}`);
  };

  const filteredAndSortedOrders = orders
    .filter(
      (order) =>
        (order.name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.email || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        (order.status || "").toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (selectedSort === "latest")
        return new Date(b.orderedAt) - new Date(a.orderedAt);
      if (selectedSort === "delivered")
        return a.status === "Delivered" ? -1 : 1;
      if (selectedSort === "outForDelivery")
        return a.status === "Out for Delivery" ? -1 : 1;
      return 0;
    });

  return (
    <div className="admin-orders-container">
      <ToastContainer />
      <div className="flexBtn">
        <div className="admin-orders-search-container">
          <input
            type="text"
            placeholder="Search order..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="admin-search-input"
          />
          <button className="admin-search-button">
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        <select
          className="admin-sort-dropdown"
          value={selectedSort}
          onChange={handleSortChange}
        >
          <option value="latest">Latest Orders</option>
          <option value="delivered">Delivered Orders</option>
          <option value="outForDelivery">Out for Delivery</option>
        </select>
      </div>
      <div className="admin-orders-table-container">
        <table>
          <thead>
            <tr>
              <th>Customer</th>
              <th>Date</th>
              <th>Price</th>
              <th>Product</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredAndSortedOrders.map((order, index) => (
              <tr key={index} onClick={() => handleOrderClick(order._id)}>
                <td>{order.name}</td>
                <td>{new Date(order.orderedAt).toLocaleDateString()}</td>
                <td>₹{order.total}</td>
                <td>{order.cart[0]?.name}</td>
                <td>{order.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminOrders;
