import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import "./Nav.css";
import { useQuote } from "../../contexts/QuoteContext";
import { useCart } from "../../contexts/CartContext";

const Nav = ({ color }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const theme = color !== "blue" ? "#EBA9D7" : "#73A9FA";
  const navigate = useNavigate();
  const { quote } = useQuote();
  const { cart } = useCart();
  const [quoteCount, setQuoteCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    setQuoteCount(quote.length);
  }, [quote]);

  useEffect(() => {
    setCartCount(cart.length);
  }, [cart]);

  const links =
    color !== "blue"
      ? [
          { to: "/b2b-product-list", text: "Product Range" },
          { to: "/b2b-product-list-brand", text: "By Brands" },
          { to: "/b2b-contact-us-page", text: "Contact Us" },
          { to: "/b2c-home-page", text: "Shop" },
        ]
      : [
          { to: "/b2b-product-list", text: "Categories" },
          { to: "/b2c-product-list-brand", text: "By Brands" },
          { to: "/by-price", text: "By Price" },
          { to: "/b2b-contact-us-page", text: "Contact Us" },
          { to: "/b2c-home-page", text: "Shop" },
        ];

  const contacts = [
    { to: "/", text: "10:00 - 06:00", iconClass: "fas fa-clock space" },
    { to: "tel:", text: "+91 9920334444", iconClass: "fas fa-phone space" },
    { to: "mailto:", text: "Mail", iconClass: "fas fa-envelope space" },
  ];

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      <div className="nav-logo-container">
        <div className="flex">
          <div className="hamburger" onClick={toggleNav}>
            <i className="fas fa-bars icon-size"></i>
          </div>
          {color !== "blue" ? (
            <img
              src="/assets/logo.svg"
              onClick={() => navigate("/")}
              class="logo"
            />
          ) : (
            <img
              src="/assets/blue-logo.png"
              onClick={() => navigate("/")}
              class="logo"
            />
          )}
        </div>
        {color !== "blue" && (
          <div className="quote-button-container">
            <Button
              label={"Quote list"}
              color="#EBA9D7"
              action={() => navigate("/b2b-qoute-page")}
            />
            {quoteCount > 0 && <span className="cart-count">{quoteCount}</span>}
          </div>
        )}
      </div>
      <div className={`navbar ${isNavOpen ? "open" : ""}`}>
        <div className="menu">
          {links.map((link, index) => (
            <Link key={index} to={link.to}>
              {link.text}
            </Link>
          ))}
        </div>
        <div className="search-bar" style={{ border: `1px solid ${theme}` }}>
          <input type="text" />
          <svg style={{ width: "24px", height: "24px" }} viewBox="0 0 24 24">
            <path
              fill="#666666"
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
        </div>
        <div className="menu">
          {contacts.map((link, index) => (
            <Link key={index} to={link.to}>
              <i className={link.iconClass}></i> {link.text}
            </Link>
          ))}
        </div>
        {color == "blue" ? (
          <>
            <div className="carts-container ">
              <i
                className="fa-solid fa-cart-shopping cart-icon"
                onClick={() => navigate("/b2c-cart-page")}
              ></i>
              {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
            </div>
            {!isLoggedIn ? (
              <i
                className="fa-solid fa-user"
                onClick={() => navigate("/login")}
                class="icon"
              ></i>
            ) : (
              <i
                class="fa-solid fa-arrow-right-from-bracket icon"
                onClick={() => navigate("/admin-logout")}
              ></i>
            )}
          </>
        ) : (
          <>
            <div className="carts-container quote-button-dis">
              <Button
                label={"Quote list"}
                color="#EBA9D7"
                action={() => navigate("/b2b-qoute-page")}
              />
              {quoteCount > 0 && (
                <span className="cart-count">{quoteCount}</span>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Nav;
