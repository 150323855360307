import React, { useState } from "react";
import Button from "../Button/Button";
import "./GetInTouch.css";
import { toast, ToastContainer } from "react-toastify";

const GetInTouch = ({ color }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    location: "",
    message: "",
  });
  const [loading, setLoading] = useState(false); // Loading state

  const theme = color !== "blue" ? "#FFE8F2" : "#D1E4FF";

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_BASE_URL;

    setLoading(true); // Start loading
    try {
      const response = await fetch(`${baseUrl}/api/consult/consulation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      console.log(result);
      setFormData({
        name: "",
        email: "",
        companyName: "",
        location: "",
        message: "",
      });
      toast.success("Submitted successfully");
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="get-in-touch-comp-container">
      <ToastContainer />
      <p className="get-in-touch-comp-header">Get in Touch</p>
      <div className="get-in-touch-comp-sub-container">
        <div className="get-in-touch-comp-dis">
          <p className="get-in-touch-comp-title">Request A Free Consultation</p>
          <p className="get-in-touch-comp-sub-title">
            Take Advantage of Our free consultation with our expert and get the
            best gift for your business
          </p>
        </div>
        <div className="get-in-touch-comp-form-container">
          <form onSubmit={handleSubmit}>
            <div className="get-in-touch-comp-field-container">
              <div className="get-in-touch-comp-row">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
              </div>
              <div className="get-in-touch-comp-row">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  value={formData.companyName}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  value={formData.location}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
              </div>
              <div className="get-in-touch-comp-row" style={{ height: "12vh" }}>
                <textarea
                  name="message"
                  placeholder="Message...."
                  value={formData.message}
                  onChange={handleChange}
                  style={{ backgroundColor: `${theme}` }}
                  disabled={loading} // Disable input when loading
                />
              </div>
            </div>
            <div className="get-in-touch-comp-button-container"></div>
            <button
              type="submit"
              className="send-btn"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <div className="loader"></div> // Display loader when loading
              ) : (
                <Button
                  label="Send"
                  color={color !== "blue" ? "#EBA9D7" : "#73A9FA"}
                  type="submit"
                  className="send-btnn"
                />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
